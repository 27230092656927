 var option = () =>(  {
    label:{
        show:true,
        position:"top"
      },
    title:{
      text:""
    },
    animation:true,
    xAxis: {
      // name: '感官描述',
      // nameLocation:'end',
      // nameStyle:{
        
      // },
      nameGap:'0',
      type: 'category',
      data: ['','',''],
      axisLabel:{
        fontWeight:'bold'
      },
    },

    yAxis: {
        // name:'平均分',
        max:10,
        // data:[1,2,3,4,5,6,7,8,9,10],
        axisLabel: {
          formatter: function(value){
            if(value == 0){
              return "0%"
            }
            return value + "0%"
          }
      }
    },
    series: [
      {
        type: 'bar',
        name: '2015',
        data: [5,6,9],
        itemStyle:{
            normal:{
                color: function(params) {
                    var colors = ['#5793f3'];//, '#d14a61', '#675bba', '#f0b13c', '#61bb52'
                    return colors[params.dataIndex % colors.length];
                },
            }
        },
        label:{
          formatter:"赞同率{c}0%"
        }
      },
    ]
  })

  export default option;