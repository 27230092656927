<template>
  <div id="app">
    <h2>消费者感官评价统计</h2>
    <span>评价人数：{{count}}</span>
    <!-- <div style="display: flex;margin-bottom: 20px;">
      <el-switch
        v-model="singleCharts"
        active-text="合并图表"
        >
      </el-switch>
    </div> -->
    <div v-show="!singleCharts" ref="charts" class="echarts"></div>
    <!-- <div v-show="singleCharts" ref="chartssin" class="echarts"></div>
    <div v-show="!singleCharts" ref="charts1" class="echarts"></div>
    <div v-show="!singleCharts" ref="charts2" class="echarts"></div> -->
  </div>
</template>

<script>
/* eslint-disable */
import * as echarts from "echarts";
import his from "./histogram";
import sin from "./SingleCharts";
import axios from 'axios';

export default {
  name: 'App',
  data(){
    return {
      singleCharts:false,
      production:"",
      count:10,
      chart:null,
      // chart1:null,
      // chart2:null,
      // chartsin:null,
      // option:null,
      evaVo:null,
    }
  },
  mounted() {
    let search = document.location.search;
    if(search){
      let paramstr = search.split('?')[1];
      let params = paramstr.split('&');
      for(let i = 0; i < params.length; i++){
        let p_arr = params[i].split('=');
        if(p_arr[0] == 'gid'){
          axios.get("http://116.205.241.218:61010//sensor/graph?gid=" + p_arr[1]).then(
            res=>{
              this.evaVo = res.data.data;
              this.updateInfo();
            }
          )
        }
      }
    }
    this.initEcharts();
  },
  watch:{
    singleCharts(newVal,old){
      if(newVal){
        this.updateSingle();
      }else{
        this.updateInfo();
      }
    }
  },
  methods:{
    sum(arr) {
      return arr.reduce(function(prev, curr, idx, arr){
          return prev + curr;
      });
    },
    updateSingle(){
      const option = sin();
      const arr = this.evaVo.evos;
      arr.forEach((element,index) => {
        option.yAxis.data[index] = element.name;
        element.values.slice(1).forEach((item,i)=>{
          option.series[i].data[index] = item;
        })
      });
      this.chartsin.setOption(option);
    },
    updateInfo(){
      this.production = this.evaVo.goods_name;
      this.option = his();
      const arr = this.evaVo.evos;
      let max_count = 0;
      arr.forEach((element,index) => {
        this.option.xAxis.data[index] = element.name;
        // option.title.text = element.name;
        // element.values.shift();
        let total = 0;
        for (let i = 0; i < element.values.length; i++) {
          const item = element.values[i];
          total += item * i;
        }
        let temp = this.sum(element.values);
        if(temp > max_count) max_count = temp;
        this.option.series[0].data[index] = Math.round(total/temp);
        // option.series[0].data = element.values.slice(1).map(item=>item/max_count * 100);
      });
      this.chart.setOption(this.option);
      this.count = max_count;
    },

    resizeDiv(){
      const width = window.innerWidth;
      const height = window.innerWidth * 2 / 3;
      this.chart._dom.style.width = width-20 + "px";
      this.chart._dom.style.height = height + "px";
      // this.chart1._dom.style.width = width-20 + "px";
      // this.chart1._dom.style.height = height + "px";
      // this.chart2._dom.style.width = width-20 + "px";
      // this.chart2._dom.style.height = height + "px";
      // this.chartsin._dom.style.width = width-20 + "px";
      // this.chartsin._dom.style.height = height + "px";
    },
    chartsResize(){
      this.chart.resize();
      // this.chart1.resize();
      // this.chart2.resize();
      // this.chartsin.resize();
    },
    initEcharts(){
      this.chart = echarts.init(this.$refs.charts);
      // this.chart1 = echarts.init(this.$refs.charts1);
      // this.chart2 = echarts.init(this.$refs.charts2);
      // this.chartsin = echarts.init(this.$refs.chartssin);
      this.resizeDiv();
      this.chartsResize();
      const resize = this.resizeDiv.bind(this);
      const chartsResize = this.chartsResize.bind(this);
      window.addEventListener('resize', resize);
      window.addEventListener('resize', chartsResize);
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  height: 100vh;
}
.echarts{
  width: 100%;
  height: 100%;
}
</style>
